<template>
  <b-row>

    <b-col md="12">
      <b-card
        title="Modèles économiques"
        no-body
      >
        <b-card-body>
          <b-row>
            <b-col md="12">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                size="sm"
                pill
                class="float-right mb-1"
                onclick="history.back()"
              >
                <feather-icon icon="ArrowLeftCircleIcon" />
                Retour
              </b-button>

              <b-button
                id="toggle-btn"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-modal.modal-modify
                class="float-left"
                variant="success"
                size="sm"
                pill
              >
                Créer une catégorie de frais
              </b-button>
            </b-col>
          </b-row>
          <div class="d-flex justify-content-between  flex-wrap">

            <!-- sorting  -->
            <b-form-group
              label="Filtrer"
              label-size="sm"
              label-align-sm="left"
              label-cols-sm="3"
              label-for="sortBySelect"
              class=" mb-md-0"
            >
              <b-input-group size="sm">
                <b-form-select
                  id="sortBySelect"
                  v-model="sortBy"
                  :options="sortOptions"
                >
                  <template #first>
                    <option value="">
                      aucun
                    </option>
                  </template>
                </b-form-select>
                <b-form-select
                  v-model="sortDesc"
                  size="sm"
                  :disabled="!sortBy"
                >
                  <option :value="false">
                    Croissant
                  </option>
                  <option :value="true">
                    Décroissant
                  </option>
                </b-form-select>
              </b-input-group>
            </b-form-group>

            <!-- filter -->
            <b-form-group
              label="Rechercher"
              label-cols-sm="3"
              label-align-sm="left"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Rechercher"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Effacer
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </div>
        </b-card-body>

        <b-table
          striped
          hover
          responsive
          small
          :busy="loader"
          class="position-relative"
          :per-page="perPage"
          empty-text="Aucun résultat trouvé"
          empty-filtered-text="Aucun résultat trouvé"
          show-empty
          :current-page="currentPage"
          :items="fraisCategories"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #table-busy>
            <div class="d-flex justify-content-center mb-3">
              <b-spinner
                style="width: 2rem; height: 2rem;"
                variant="primary"
                label="Large Spinner"
                type="grow"
                class="mt-2"
              />
              <span class="sr-only">Chargement en cours...</span>
            </div>
          </template>
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              v-b-tooltip.hover.bottom="'Modifier un modèle économique'"
              v-b-modal.modal-modify
              variant="gradient-warning"
              size="sm"
              class="btn-icon rounded-circle ml-1"
              @click.prevent="getItem(data.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </template>
        </b-table>

        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

          <!-- page length -->
          <b-form-group
            label="Par Page"
            label-md="6"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>

          <!-- pagination -->
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="fraisCategories.length"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class=" pagination-primary mb-0 pagination-center"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </b-card-body>

      </b-card>
    </b-col>

    <b-modal
      id="modal-modify"
      ref="myModal"
      no-close-on-backdrop
      centered
      button-size="sm"
      :modal-class="form.id ? 'modal-warning' : 'modal-success'"
      :title="form.id ? 'Modifier la catégorie de frais' : 'Ajouter une catégorie de frais'"
      :busy="categoryFraisProcess"
      :ok-title="form.id ? 'Modifier' : 'ajouter'"
      cancel-title="Fermer"
      cancel-variant="secondary"
      ok-variant="warning"
      size="sm"
      @ok="handleUpdateOk"
    >
      <validation-observer ref="modeleEconomiqueCreationRules">
        <b-form>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Libellé"
                label-for="libelle"
                rules="required"
              >
                <validation-provider
                  #default="{ errors }"
                  name="libellé"
                  rules="required"
                >
                  <b-form-input
                    id="libelle"
                    v-model="form.libelle"
                    placeholder="Libellé"
                    size="sm"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Description"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  name="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="form.description"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Description "
                    rows="3"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-row>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import {
  ref, onMounted, reactive, computed,
} from '@vue/composition-api'
import {
  VBTooltip, BRow, BCol, BCard, BTable, BFormTextarea, BFormGroup, BFormSelect, BPagination, BInputGroup, BInputGroupAppend, BButton, BCardBody, BFormInput, BForm, BSpinner, VBModal,
} from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required,
} from '@validations'
// eslint-disable-next-line import/no-cycle
import useModeleEconomiques from '@/composables/modeleEconomiqueService'

// eslint-disable-next-line import/no-cycle
import useProprietes from '@/composables/proprieteService'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    BForm,
    BSpinner,
    ValidationObserver,
    ValidationProvider,
    BFormTextarea,

  },
  directives: {
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,

    Ripple,
  },
  setup() {
    const perPage = 10
    const pageOptions = [3, 5, 10]
    const totalRows = ref(1)
    const currentPage = ref(1)
    const sortBy = ''
    const sortDesc = false
    const sortDirection = 'asc'
    const filter = null
    const filterOn = []
    const errors = []

    const {
      getAllCategorieFraisCopropriete, fraisCategories, handleCategoryFrais, categoryFraisProcess, categoryFraisSuccess, handleUpdateCategoryFrais, loader,
    } = useProprietes()

    const fields = [
      { key: 'index', label: 'N°' },
      { key: 'libelle', label: 'Libellé', sortable: true },
      { key: 'description', label: 'Description', sortable: true },
      { key: 'actions' },
    ]

    const form = reactive({
      id: null,
      title: '',
    })

    const onFiltered = filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      totalRows.value = filteredItems.length
      currentPage.value = 1
    }

    // Reset Form
    const resetForm = () => {
      form.id = null
      form.title = ''
    }

    const updateCategoryFrais = async () => {
      await handleUpdateCategoryFrais({ ...form })
    }
    const createCategoryFrais = async () => {
      await handleCategoryFrais({ ...form })
    }
    const getItem = async item => {
      form.id = item.id
      form.libelle = item.libelle
      form.description = item.description
    }

    const formatter = value => value.toUpperCase()
    const sortOptions = computed(() => fields.filter(f => f.sortable).map(f => ({ text: f.label, value: f.key })))

    onMounted(async () => {
      await getAllCategorieFraisCopropriete()

      // Set the initial number of items
      totalRows.value = fraisCategories.value.length
    })
    // Variable ref de validation du modal
    const modeleEconomiqueCreationRules = ref('')
    // Variable ref du modal
    const myModal = ref('')

    const handleSubmitModal = () => {
      modeleEconomiqueCreationRules.value.validate().then(async success => {
        if (success) {
          // eslint-disable-next-line no-unused-expressions
          if (form.id === null) await createCategoryFrais({ ...form })
          else await updateCategoryFrais({ ...form })
          if (categoryFraisSuccess.value) myModal.value.toggle('#toggle-btn')
        }
      })
    }

    //  Fonction de soumission du modal
    const handleUpdateOk = bvModalEvt => {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      handleSubmitModal()
    }

    return {

      getItem,
      sortOptions,
      loader,
      categoryFraisProcess,
      categoryFraisSuccess,

      perPage,
      pageOptions,
      totalRows,
      currentPage,
      sortBy,
      sortDesc,
      sortDirection,
      filter,
      filterOn,
      fields,
      onFiltered,
      form,
      errors,
      formatter,
      resetForm,
      required,
      handleUpdateOk,
      modeleEconomiqueCreationRules,
      myModal,

      fraisCategories,

    }
  },

}
</script>
